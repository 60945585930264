<template>
  <div id="app">

    <Navigation />

    <b-container>
      <router-view/>
    </b-container>
  </div>
</template>

<script>
  import Navigation from '@/components/Navigation.vue'

  export default {
    components: {Navigation}
  }
</script>

<style>
  div.container {
    padding-top: 50px;
  }
</style>

