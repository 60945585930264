import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faClock, faQuestionCircle } from '@fortawesome/free-regular-svg-icons'
import { faArrowUp, faArrowRotateRight, faCalendar, faCalendarCheck, faCalendarMinus, faCalendarPlus, faCheckSquare, faCaretDown, faSquare, faSquareXmark, faHourglassHalf, faTrashAlt, faUser, faUserNinja, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import App from './App.vue'
import { sync } from 'vuex-router-sync'
import router from './router'
import store from './store'
const fb = require('@/firebaseConfig.js')
const unsync = sync(store, router)

Vue.config.productionTip = false

// Bootstrap
Vue.use(BootstrapVue)

// FontAwesome
library.add(faArrowUp)
library.add(faArrowRotateRight)
library.add(faCalendar)
library.add(faCalendarCheck)
library.add(faCalendarMinus)
library.add(faCalendarPlus)
library.add(faCheckSquare)
library.add(faCaretDown)
library.add(faClock)
library.add(faHourglassHalf)
library.add(faQuestionCircle)
library.add(faSquare)
library.add(faSquareXmark)
library.add(faTrashAlt)
library.add(faUser)
library.add(faUserNinja)
library.add(faUserPlus)
Vue.component('font-awesome-icon', FontAwesomeIcon)

// Only initialize the app when we are sure
// Firebase Auth object is ready to use.
let app = '';
fb.auth.onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App),
      destroyed: () => { unsync(); }
    }).$mount('#app');
  }
});
