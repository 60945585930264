<template>
  <div class="version">{{ ver }} ({{ gitHash }})</div>
</template>

<script>
  import GitHash from '@/assets/hash.json';

  export default {
    data() {
      return {
        gitHash: GitHash,
        ver: process.env.VUE_APP_VERSION
      }
    }
  }
</script>

<style>
  div.version {
      color: #dddddd;
      font-size: smaller;
      position: absolute;
      bottom: 0;
      left: 0;
  }
</style>
