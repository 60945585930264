import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY || 'api-key-not-set',
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN || 'demo-ujjk',
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL || 'demo-ujjk',
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID || 'demo-ujjk',
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET || 'demo-ujjk',
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID || 'demo-ujjk',
  appId: process.env.VUE_APP_FIREBASE_APP_ID || 'demo-ujjk',
};
firebase.initializeApp(firebaseConfig);

// firebase utils
const db = firebase.firestore();
const functions = firebase.app().functions(process.env.VUE_APP_FUNCTIONS_REGION || 'europe-west6');

const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp;
const arrayUnion = firebase.firestore.FieldValue.arrayUnion;
const documentId = firebase.firestore.FieldPath.documentId;
const auth = firebase.auth();
const currentUser = auth.currentUser;

// Initializations for local testing
if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
  // auth
  auth.useEmulator('http://localhost:9099');

  //functions
  functions.useEmulator('localhost', 5001);

  // db
  db.settings({
    merge: true,
    experimentalForceLongPolling: true // Workaround for https://github.com/cypress-io/cypress/issues/2374
  });
  db.useEmulator('localhost', 8080);
}

export {
  auth,
  db,
  functions,
  arrayUnion,
  serverTimestamp,
  documentId,
  currentUser
}
