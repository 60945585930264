<template>
  <div class="mx-auto" style="width: 400px;">
     <b-alert class="my-4" show><b>Tränat med oss 2021 eller senare?</b><hr/> Logga in med samma epostadress som du angav vid din senaste anmälan.</b-alert>
     <!-- b-alert class="my-4" variant="warning" show><b>Anmälan till ht-2022 är stängd.</b><hr/>Anmälan till vt-2023 öppnar den 18 december.</b-alert -->
    <b-card bg-variant="light">
      <div class="text-center">
        <img src="../assets/logo_60x60.png" alt="UJJK logo"/>
      </div>
      <b-form v-if="emailConfirmationRequired" @submit="confirmEmail">
        <h4>Nästan klart...</h4>
        <b-card-text>
          Du behöver skriva din epostadress en gång till för att bekräfta inloggningen:
        </b-card-text>
        <b-form-group
          id="login-group-email"
          >
          <b-form-input
            id="login-email"
            ref="login-email"
            placeholder="Epost"
            v-model="email"
            type="email"
            required
            ></b-form-input>
        </b-form-group>
        <font-awesome-icon :icon="['far', 'question-circle']" size="2x" class="mt-1" id="help-icon"></font-awesome-icon>
        <b-button class="float-right" type="submit" variant="primary" :disabled="this.submitting">
          <b-spinner v-if="submitting" small></b-spinner>
          Logga in
        </b-button>
      </b-form>

      <b-form v-else @submit="login">
        <h4 class="mt-3 text-center">Anmälan till<br/>Uppsala ju-jutsuklubb</h4>
        <b-card-text class="mt-3">
          Oavsett om du är här första gången, eller är medlem sedan tidigare, börja med att ange din epostadress här:
        </b-card-text>
        <b-form-group
          id="login-group-email"
          description="En länk för inloggning kommer skickas till dig"
          >
          <b-form-input
            id="login-email"
            ref="login-email"
            placeholder="Epost"
            v-model="email"
            type="email"
            required
            ></b-form-input>
        </b-form-group>
        <font-awesome-icon :icon="['far', 'question-circle']" size="2x" class="mt-1" id="help-icon"></font-awesome-icon>
        <b-button class="float-right" type="submit" variant="primary" :disabled="this.submitting">
          <b-spinner v-if="submitting" small></b-spinner>
          Skicka länk
        </b-button>
      </b-form>

      <b-popover target="help-icon" triggers="hover" placement="bottom">
        <template #title>Har du frågor?</template>
        Kontakta <a href="mailto:info@ujjk.se">info@ujjk.se</a>.
      </b-popover>
      <b-modal ref="email-login-info-modal" header-bg-variant="dark" header-text-variant="light" title="Kolla din epost" ok-only>
        <p class="my-4">Ett mail har skickats till <b>{{ email }}</b>. Öppna mailet och klicka på länken i mailet för att logga in.</p>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex';

  export default {
    async mounted() {
      await this.finishSignupAction()
      this.$refs['login-email'].focus()
    },
    data() {
      return {
        email: '',
        submitting: false,
        submitted: false
      }
    },
    computed: {
      ...mapState('user', ['status', 'error', 'emailConfirmationRequired']),
      title: function () { return this.emailConfirmationRequired ? '' : 'Anmäl dig eller logga in' }
    },
    methods: {
      ...mapActions('user', ['loginEmailAction', 'confirmEmailAction', 'finishSignupAction']),
      showModal: function() {
        this.$refs['email-login-info-modal'].show()
      },
      login: function (event) {
        this.submitting = true
        event.preventDefault();
        const credentials = {
          email: this.email,
        }
        this.loginEmailAction(credentials)
          .then(() => {
            this.submitting = false
            this.showModal()
            if (this.status === 'success') {
              this.$router.replace('/profile')
            } else if (this.status === 'failure') {
              let error = this.error;
              alert(error.message);
            }
          })
          .catch(error => {
            console.error('Caught error', error)
          });
      },

      confirmEmail: function(event)  {
        event.preventDefault();
        const credentials = {
          email: this.email,
        }
        this.confirmEmailAction(credentials)
          .then(() => {
            if (this.status === 'success') {
              this.$router.replace('/')
            } else if (this.status === 'failure') {
              let error = this.error;
              alert(error.message);
            }
          })
          .catch(error => {
            console.error('Caught error', error)
          });

      }
    }
  }
</script>
