import Vue from 'vue'
import Vuex from 'vuex'
import { auth } from '@/firebaseConfig.js'
import { vuexfireMutations } from 'vuexfire'
import UserModule from '@/store/user'
import PaymentsModule from '@/store/payments'
import AttendanceModule from '@/store/attendance'

Vue.use(Vuex)

// handle page reload
auth.onAuthStateChanged(user => {
  store.dispatch('user/setUserAction', user);
});

const store = new Vuex.Store({
  modules: {
    payments: PaymentsModule,
    user: UserModule,
    attendance: AttendanceModule
  },
  mutations: {
    ...vuexfireMutations
  }
})

export default store;
