import { auth, db, serverTimestamp } from '@/firebaseConfig.js'
import route from '@/router'

export default {
  namespaced: true,
  state: {
    user: null,
    status: null,
    error: null,
    emailConfirmationRequired: false
  },
  getters: {
    getUsername: (state) => {
      if (state.user) {
        if (state.user.name) return state.user.name;
        else if (state.user.email) return state.user.email;
      }
    },
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    SET_LOGIN_STATUS(state, status) {
      state.status = status;
    },
    SET_LOGIN_ERROR(state, error) {
      state.error = error;
    },
    SET_EMAIL_CONFIRMATION_REQUIRED(state, required) {
      state.emailConfirmationRequired = required;
    }
  },
  actions: {
    loginAction({ commit }, credentials) {
      auth.signInWithEmailAndPassword(credentials.email, credentials.password)
        .then(() => {
          commit('SET_LOGIN_STATUS', 'success')
          commit('SET_LOGIN_ERROR', null)
        })
        .catch(error => {
          commit('SET_LOGIN_STATUS', 'failure')
          commit('SET_LOGIN_ERROR', { code: error.code, message: error.message })
        })
    },
    loginEmailAction({ commit }, credentials) {
      const baseUrl = process.env.VUE_APP_BASE_URL || 'http://localhost:5000/'
      const actionCodeSettings = {
        handleCodeInApp: true,
        url: baseUrl + 'login'
      }
      console.log(`Sending link to ${credentials.email} with ${JSON.stringify(actionCodeSettings)}`)
      auth.sendSignInLinkToEmail(credentials.email, actionCodeSettings)
        .then(() => {
          window.localStorage.setItem('emailForSignIn', credentials.email)
          commit('SET_LOGIN_STATUS', 'pending')
          commit('SET_LOGIN_ERROR', null)
        })
        .catch(error => {
          commit('SET_LOGIN_STATUS', 'failure')
          commit('SET_LOGIN_ERROR', { code: error.code, message: error.message })
        })
    },
    confirmEmailAction({ dispatch, commit }, credentials) {
      commit('SET_EMAIL_CONFIRMATION_REQUIRED', false)
      const url = window.location.href
      const email = credentials.email
      if (auth.isSignInWithEmailLink(url) && email) {
        dispatch('signInWithEmailLink', { email, url })
      } else {
        console.error(`Not able to login by email ${email}`)
      }
    },
    finishSignupAction({ dispatch, commit }) {
      const url = window.location.href
      if (auth.isSignInWithEmailLink(url)) {
        const email = window.localStorage.getItem('emailForSignIn')
        console.log(`Got email ${email} from local storatge`)
        if (email) {
          dispatch('signInWithEmailLink', { email, url })
        } else {
          commit('SET_EMAIL_CONFIRMATION_REQUIRED', true)
        }
      }
    },
    signInWithEmailLink({ commit }, {email, url}) {
      console.log(`Signing in with email ${email}`)
      auth.signInWithEmailLink(email, url)
        .then((result) => {
          console.log(`Result was ${JSON.stringify(result)}`)
          window.localStorage.removeItem('emailForSignIn');
          route.push('/profile')
        })
        .catch((error) => {
          commit('SET_LOGIN_STATUS', 'failure')
          commit('SET_LOGIN_ERROR', { code: error.code, message: error.message })
        })
    },
    logoutAction({ commit }) {
      auth.signOut().then(() => {
        commit('SET_USER', null)
        commit('SET_LOGIN_STATUS', 'success')
        commit('SET_LOGIN_ERROR', null)
        route.go(0) // Refresh the current route
      }).catch((error) => {
        commit('SET_LOGIN_STATUS', 'failure')
        commit('SET_LOGIN_ERROR', { code: error.code, message: error.message })
      });
    },
    setUserAction({ commit }, user) {
      if (user) {
        auth.currentUser.getIdTokenResult().then((idTokenResult) => {
          const claims = idTokenResult.claims
          const setUser = {
            id: claims.user_id,
            email: claims.email,
            createdAt: serverTimestamp(),
            admin: claims.admin === true,
            instructor: claims.instructor === true
          };
          db.collection('users').doc(setUser.id).set(setUser)
            .catch(function(error) {
              console.error('Failed to save newly created user: ', error)
            });
          commit('SET_USER', setUser)
        })
      } else {
        commit('SET_USER', null)
      }
    },
  }
}
