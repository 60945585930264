<template>
  <div class="mx-auto" style="width: 400px;">
    <b-card bg-variant="light">
      <div class="text-center">
        <img src="../assets/logo_60x60.png" alt="UJJK logo"/>
      </div>
      <b-form @submit="login">
        <b-form-group
          id="login-group-email"
          label="Epost:"
          label-for="login-email"
          description="Innan du kan logga in här måste du ha fått ett lösenord av administratören."
          >
          <b-form-input
            id="login-email"
            ref="login-email"
            v-model="email"
            type="email"
            required
            ></b-form-input>
        </b-form-group>

        <b-form-group
          id="login-group-password"
          label="Lösenord:"
          label-for="login-password"
          >
          <b-form-input
            id="login-password"
            type="password"
            v-model="password"
            required
            ></b-form-input>
        </b-form-group>

        <b-button class="float-right" type="submit" variant="primary" :disabled="this.submitted">Logga in</b-button>
      </b-form>
      <Version />
    </b-card>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import Version from '@/components/Version.vue'

  export default {
    components: {Version},
    mounted() {
      this.$refs['login-email'].focus();
    },
    data() {
      return {
        email: '',
        password: '',
        submitting: false,
        submitted: false
      }
    },
    computed: {
      ...mapState('user', ['status', 'error'])
    },
    methods: {
      ...mapActions('user', ['loginAction']),

      login: function (event) {
        event.preventDefault();
        const credentials = {
          email: this.email,
          password: this.password
        }
        this.loginAction(credentials)
          .then(() => {
            console.log('Status', this.status)
            if (this.status === 'success') {
              this.$router.replace('/')
            } else if (this.status === 'failure') {
              let error = this.error;
              if (error.code === 'auth/wrong-password') {
                alert('Kontrollera att användarnamn och lösenord är korrekta.');
              } else {
                alert(error.message);
              }
            }
          })
          .catch(error => {
            console.error('Caught error', error)
          });
      }
    }
  }
</script>
