import { arrayUnion, db } from '@/firebaseConfig.js'
import { firestoreAction } from 'vuexfire'

export default {
  namespaced: true,
  state: {
    person: undefined,
    payments: [],
    fees: []
  },
  mutations: {
    SET_PERSON(state, person) {
      state.person = person
    },

    ADD_PAYMENTS(state, newPayments) {
      state.payments.push(...newPayments)
    },
    ADD_FEES(state, newFees) {
      state.fees.push(...newFees)
    }
  },
  actions: {
    bindPayments: firestoreAction(({bindFirestoreRef}, memberId) => {
      return bindFirestoreRef('payments', db.collection('payments').where("memberId", "==", memberId).orderBy('date'));
    }),
    unbindPayments: firestoreAction(({ unbindFirestoreRef }) => {
      return unbindFirestoreRef('payments');
    }),
    bindFees: firestoreAction(({bindFirestoreRef}, memberId) => {
      return bindFirestoreRef('fees', db.collection('fees').where("memberId", "==", memberId));
    }),
    unbindFees: firestoreAction(({ unbindFirestoreRef }) => {
      return unbindFirestoreRef('fees');
    }),
    addPayment({ state }, payment) {
      const pment = { ...payment, memberId: state.person.id }
      db.collection('payments').add(pment)
    },
    async addFee({state}, fee) {
      const theFee = { ...fee, memberId: state.person.id }
      const promises = []
      const docRef = await db.collection('fees').add(theFee)
      db.collection('groupMembers').where('memberId', '==', theFee.memberId).get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            promises.push(doc.ref.update({
              fees: arrayUnion({...fee, feeId: docRef.id})
            }))
          })
        })
        .catch((error) => console.error('Failed to update groupMembers for ', JSON.stringify(fee), error))
      await Promise.all(promises).then(() => console.log('Updated all groupmembers'))
      await db.collection('members').doc(theFee.memberId).update({ fees: arrayUnion({ ...fee, feeId: docRef.id }) })
    },
    deletePayment(context, paymentId) {
      db.collection('payments').doc(paymentId).delete()
    },

    /** Take the information from a prospect and use it to create a member */
    createMemberFromProspect({dispatch, state}) {
      if (!state.person.isProspect) {
        console.error('The currently selected person is not a prospect')
        return
      }

      const prospect = state.person
      const member = {
        firstName: prospect.firstName,
        lastName: prospect.lastName,
        prospectId: prospect.id,
        birthdate: prospect.personalIdNumber.substring(0,8),
        group: prospect.group,
        signups: [ prospect.period ],
        firstSignup: prospect.period
      }
      if (prospect.userId) {
        member.userId = prospect.userId
      }
      if (prospect.managedByGuardian) {
        member.managedByGuardian = true
      }
      db.collection('members').add(member)
        .then(async (docRef) => {
          member.id = docRef.id

          await Promise.all([
            dispatch('savePersonnummer', { memberId: member.id, personalIdNumber: prospect.personalIdNumber}),
            dispatch('saveContacts', { ...prospect, memberId: member.id}),
            dispatch('changeGroupMemberToMember', { prospectId: prospect.id, memberId: member.id })])
          return dispatch('setPerson', member)
        })
        .catch((error) => console.error('Failed to create member ', error))
    },

    /** Save a personnummer in a members subcollection */
    savePersonnummer(context, data) {
      return db.collection('members').doc(data.memberId)
        .collection('private').doc('pnr')
        .set({personalIdNumber: data.personalIdNumber})
    },

    /** Save contacts in a members subcollection */
    saveContacts(context, data) {
      let contacts = {
        email: data.email,
        phoneNumber: data.phoneNumber
      }
      if (data.subject == 'child') {
        contacts.guardian = {
          firstName: data.guardianFirstName,
          lastName: data.guardianLastName
        }
      }
      return db.collection('members').doc(data.memberId)
        .collection('private').doc('contacts')
        .set(contacts)
    },

    /** Find all groupMembers matching the prospectId and change it to member */
    changeGroupMemberToMember(context, member) {
      const promises = []
      db.collection('groupMembers').where("prospectId", "==", member.prospectId).get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            promises.push(doc.ref.update({
              isProspect: false,
              memberId: member.memberId
            }))
          })
          return Promise.all(promises)
        })
    },
    setPerson({commit, dispatch}, person) {
      commit('SET_PERSON', person)
      console.log('Now binding payments and fees for', person.id)
      Promise.all([
        dispatch('bindPayments', person.id),
        dispatch('bindFees', person.id)])
    }
  }
}
