import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginEmail from '@/views/LoginEmail.vue'
import LoginPwd from '@/views/LoginPwd.vue'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: '/profile'
  },
  {
    path: '/',
    redirect: '/profile'
  },
  {
    path: '/anmalan',
    name: 'anmalan',
    component: () => import(/* webpackChunkName: "anmalan" */ '../views/RegisterProspect.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/promotions',
    name: 'promotions',
    component: () => import(/* webpackChunkName: "promotions" */ '../views/Promotions.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/intressenter',
    name: 'intressenter',
    component: () => import(/* webpackChunkName: "intressenter" */ '../views/Prospects.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/attendance',
    name: 'attendance',
    component: () => import(/* webpackChunkName: "attendance" */ '../views/Attendances.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/staff',
    name: 'staff',
    component: () => import(/* webpackChunkName: "staff" */ '../views/Staff.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/payments',
    name: 'payments',
    component: () => import(/* webpackChunkName: "payments" */ '../views/Payments.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/ranks',
    name: 'ranks',
    component: () => import(/* webpackChunkName: "ranks" */ '../views/Ranks.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/functions',
    name: 'functions',
    component: () => import(/* webpackChunkName: "functions" */ '../views/Functions.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/members',
    name: 'members',
    component: () => import(/* webpackChunkName: "members" */ '../views/Members.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "users" */ '../views/Users.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/reports',
    name: 'reports',
    component: () => import(/* webpackChunkName: "reports" */ '../views/Reports.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/belts',
    name: 'belts',
    component: () => import(/* webpackChunkName: "belts" */ '../views/Belts.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/cards',
    name: 'cards',
    component: () => import(/* webpackChunkName: "cards" */ '../views/Cards.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginEmail
  },
  {
    path: '/login-pwd',
    name: 'login-pwd',
    component: LoginPwd
  },
  {
    path: '/groups',
    name: 'groups',
    component: () => import(/* webpackChunkName: "groups" */ '../views/Groups.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/group/:id/',
    name: 'group',
    component: () => import(/* webpackChunkName: "group" */ '../views/Group.vue'),
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  const currentUser = firebase.auth().currentUser;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if (requiresAuth && !currentUser) next('login');
  else next();
});

export default router
